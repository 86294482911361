<template>

  <div style="border : 1px solid var(--wsDARKLIGHT)" class="pa-4 wsRoundedLight">

    <!-- Select Category-->
    <div   v-if="!displayEditCategory">
      <news-categories-list

          @select="selectCategory"
          @open-new="openNewCategory"
          @edit="openEditCategory"
          :items="parentCategories"
          :selected-categories="selectedCategories"
      />

      <ws-button
          class="mt-2"
          @click="$emit('close')"
          :label="'Finish'"
          block
          outlined
      />
    </div>


    <!-- Edit Category-->
    <div v-else>
      <div
          class="d-flex justify-space-between align-center ">
        <h5 @click="goBack"
            :style="`color : ${wsACCENT}`"
            class="pointer text-no-wrap"
        >
          <v-icon class="mr-2" :color="wsACCENT">mdi-arrow-left</v-icon>
          {{ $t('GoBack') }}
        </h5>
        <h5 class="text-right shorten-text">{{ !categoryEntityData.uuid ? $t('AddCategory') : $t('EditCategory') }}</h5>
      </div>

      <ws-text-field
          class="mt-6 "
          @change="editCategory(item)"
          v-model="categoryEntityData.text"
          :label="$t('Name')"
          :placeholder="$t('EnterCategoryName')"
          avalon-style
      />

      <news-categories-list
          v-if="!categoryEntityData.parent"
          class="mt-5"
          :items="childCategories"
          @select="selectCategory"
          @open-new="openNewCategory(categoryEntityData.value)"
          @edit="openEditCategory"
          :selected-categories="selectedCategories"
          child
      />

      <div  class="d-flex justify-center mt-3 ">
        <v-btn
            v-if="categoryEntityData.value"
            @click="openDeleteCategory"
            :color="wsACCENT"
            icon
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>



    </div>

    <ws-dialog
        v-if="displayDeleteCategory"
        v-model="displayDeleteCategory"
        @delete="deleteCategory"
        :title="$t('DeleteCategory')"
        display-confirm-delete
        close-delete
    />


  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import newsCategoriesList from "@/components/AvalonEditor/Dialogs/Page/UI/newsCategoriesList.vue";

export default {
  name: "newsCategorySelector",
  components : {
    newsCategoriesList
  },
  props : {
    value : {
      type : Array,
      default() { return [] }
    },
    children : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      displayEditCategory : false,
      displayDeleteCategory : false,
      entityData : {categories : []},
      selectedCategories : [],
      categoryEntityData : {}
    }
  },
  computed : {
    ...mapState('avalon' , [
      'selectedNews' ,
      'selectedLang' ,
    ]),
    ...mapState('news' , [
      'categoriesSelect',
    ]),

    parentCategories() {
      return this.categoriesSelect.filter(el => !el.parent)
    },
    childCategories() {
      if ( !this.categoryEntityData.value ) {
        return []
      }
      return this.categoriesSelect.filter(el => el.parent === this.categoryEntityData.value )
    }

  },
  watch : {
    value() {
      if ( !this.EQ(this.value, this.selectedCategories)) {
        this.selectedCategories = this.COPY(this.value)
      }
    },
    selectedCategories() {
      if ( !this.EQ(this.value, this.selectedCategories)) {
        this.$emit(this.COPY(this.value))
      }
    }
  },
  methods : {
    ...mapActions('news', [
      'GET_NEWS_CATEGORIES',
      'ADD_EDIT_NEWS_CATEGORY',
      'DELETE_NEWS_CATEGORY',
    ]),

    returnToParent() {
      if ( this.categoryEntityData.parent ) {
        const parent = this.categoriesSelect.find(el => el.value === this.categoryEntityData.parent)
        this.openEditCategory(parent)
        return true
      }
      return false
    },
    goBack() {
      if (this.returnToParent()) {
        return
      }
      this.displayEditCategory = false
    },
    async openNewCategory(parent) {
      let data = { lang : this.selectedLang || 'ua' , text : this.$t('NewCategory') }
      if ( parent ) {
        data.parent = parent
      }
      let result = await this.ADD_EDIT_NEWS_CATEGORY(data)
      if ( !result ) {
        return
      }
      this.categoryEntityData = result
      this.displayEditCategory =  true
    },

    async editCategory() {
      this.categoryEntityData.lang = this.selectedLang
      await this.ADD_EDIT_NEWS_CATEGORY(this.categoryEntityData)
    },

    async deleteCategory() {
      await this.DELETE_NEWS_CATEGORY(this.categoryEntityData.value)
      this.displayDeleteCategory = false

      if ( this.returnToParent()) {
        return
      }

      this.categoryEntityData = {}
      this.displayEditCategory = false

    },

    selectCategory (item) {
      this.$emit('select', item)
    },

    openEditCategory(item) {
      this.categoryEntityData = this.COPY(item)
      this.displayEditCategory =  true
    },

    openDeleteCategory( ) {
      this.displayDeleteCategory = true
    } ,
  },
  mounted() {
    if ( this.value && this.value.length) {
      this.selectedCategories = this.COPY(this.value)
    }
  }
}
</script>


<style scoped>

</style>